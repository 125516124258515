@import "../common/color.css";

[view=markdown] {
	/* headers */
	h1 {
		font-size: 2rem;
		text-shadow: 1px 1px 5px var(--gray);
	}
	h2 {
		font-size: 1.7rem;
		border-bottom: 1px solid var(--gray);
	}
	h3 {
		font-size: 1.5rem;
		border-bottom: 1px solid var(--light-gray);
	}
	h4 {
		font-size: 1.3rem;
		text-decoration: underline;
	}
	h5 {
		font-size: 1.2rem;
	}
	h6 {
		font-size: 1rem;
	}
	

	a {
		color: var(--link-fg-color);
		text-decoration: none;
	}
	a:hover {
		text-decoration: underline;
	}
	table {
		border-collapse: collapse;
	}
	table th, table td {
		border: 1px solid var(--gray);
	}
}
@import "./common/reset.css";
@import "./common/color.css";
@import "./system/grid.css";
@import "./pages/markdown.css";
@import "./components/navigation-bar.css";

[page] {
    max-width: 1200px;
    margin: auto;
    padding: 0 1rem;
    
}

body {
    overflow-y: scroll;
    
    background: var(--contents-padding-bg-color);
}

header {
    z-index: 1;
}
main {
    section[page] {
        background: var(--contents-bg-color);
        min-height: 30rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
}

textarea[indent=tab] {
    tab-size: 8ch;
    
    &[tab-size=4] {
        tab-size: 4ch;
    }
    &[tab-size=2] {
        tab-size: 2ch;
    }
}

/*****************************************/
main section[page] {
    /*word-break: break-word;*/
    overflow-wrap: anywhere;
    word-break: normal;
    
    textarea {
        width: 100%;
        min-height: 20rem;
        resize: vertical;
        tab-size: 4ch;
        font-family: monospace;
    }
    
    table {
        th, td {
            border: 1px solid gray;
        }
    }
    
    [font=monospace] {
        font-family: monospace;
    }
}
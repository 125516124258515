@import url("../material/colors.css");

:root, :host {
/*
	color : #04D3F5;
	color : #3404F5;
	color : #0853E8;
	color : #049AFF;
	color : #0416FF;
*/

/* --blue: #428bca; */

	--blue       : #0416ff;
	--light-blue : #049aff;
	--skyblue    : #bfe4ff;
	--black      : #101010;
	--white      : #ffffff;
	--gray       : #919191;
	--light-gray : #d1d1d1;
	--dark-gray  : #333333;


	/* theme */
	--header-bg-color: var(--black);
	--header-fg-color: var(--white);

	--menu-fg-color: var(--skyblue);
	--menu-hover-bg-color: var(--dark-gray);

	--sub-menu-bg-color: var(--dark-gray);
	--sub-menu-hover-bg-color: var(--gray);

	--breadcrumbs-fg-color: var(--gray);
	--breadcrumbs-hover-fg-color: var(--white);

	--contents-padding-bg-color: var(--light-gray);
	--contents-bg-color: var(--white);

	--button-bg-color: var(--skyblue);
	--button-fg-color: var(--gray);
	--button-hover-fg-color: var(--gray);
	--button-selected-fg-color: var(--black);

	--link-fg-color: var(--light-blue);
}
